import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout, PostCard, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';

import styled from 'styled-components';

const StyledAuthor = styled.div`
    .author-header {
        display: flex;
        justify-content: space-between;
        margin: 0 0 4vw;
    }

    .author-header h1 {
        margin: 0 0 1rem 0;
    }

    .author-header p {
        margin: 0;
        color: var(--color-secondary);
        font-size: 2.2rem;
        line-height: 1.3em;
    }

    .author-header-image {
        flex: 0 0 auto;
        margin: 0 0 0 4vw;
        height: 120px;
        width: 120px;
        border-radius: 100%;
        overflow: hidden;
    }

    .author-header-meta {
        display: flex;
        margin: 1rem 0 0 0;
    }

    .author-header-item {
        display: block;
        padding: 2px 10px;
        color: var(--color-primary);
        font-weight: bolder;
        font-family: var(--font-poppins);
    }
    .author-header-item:hover,
    :focus {
        color: white;
        text-decoration: none;
    }

    .author-header-item:first-child {
        padding-left: 0;
    }

    @media (max-width: 500px) {
        .author-header {
            border-bottom: var(--color-bg) 1px solid;
            padding-bottom: 4vw;
        }

        .author-header p {
            font-size: 1.7rem;
        }

        .author-header-image {
            height: 80px;
            width: 80px;
        }
    }
`;
/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 */
const Author = ({ data, location, pageContext }) => {
    const author = data.ghostAuthor;
    const posts = data.allGhostPost.edges;
    const twitterUrl = author.twitter
        ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}`
        : null;
    const facebookUrl = author.facebook
        ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}`
        : null;

    return (
        <StyledAuthor>
            <MetaData data={data} location={location} type="profile" />
            <Layout>
                <div className="container">
                    <header className="author-header">
                        <div className="author-header-content">
                            <h1>{author.name}</h1>
                            {author.bio && <p>{author.bio}</p>}
                            <div className="author-header-meta">
                                {author.website && (
                                    <a
                                        className="author-header-item"
                                        href={author.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Website
                                    </a>
                                )}
                                {twitterUrl && (
                                    <a
                                        className="author-header-item"
                                        href={twitterUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Twitter
                                    </a>
                                )}
                                {facebookUrl && (
                                    <a
                                        className="author-header-item"
                                        href={facebookUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Facebook
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="author-header-image">
                            {author.profile_image && (
                                <img
                                    src={author.profile_image}
                                    alt={author.name}
                                />
                            )}
                        </div>
                    </header>
                    <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </StyledAuthor>
    );
};

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Author;

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
